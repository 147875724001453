body {
  margin: 0;
  height: 100%;
  min-width: 350px;
  /*min-height: 100vh;*/
  min-height: -webkit-fill-available;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline:none !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.container {
	height: 100vh;
  padding: 2vh;   
  /*max-width: 800px !important;*/
  max-width: 80vh !important;
}

@media only screen and (max-width: 400px) and (orientation : portrait) {
  .container {
    padding: 2vh 2vh 18vh 2vh;
  }
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {
  .container {
    padding: 2vh 2vh 10vh 2vh;  /* used to be 18vh */
  }
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
  .container {
    padding: 2vh 2vh 10vh 2vh;
  }
}

.row {
  height: 100%;
}

.col {
  height: 100%;
}

.card {
  height: 100%;
}

.file-upload input[type='file'] {
  display: none;
}

.btn {
  font-size: 1.2rem !important;
}

.btn-rotate {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 100%;
  height: 100%
}

.btn-rotate:hover {
  opacity: 0.8;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none !important;
}

.unselectable:focus-within {
  outline: none !important;
}

model-viewer /deep/ canvas:focus {
  outline:none !important;
}

#modelViewer::shadow .container canvas {
  padding: 50px;
}

.sketch-picker {
  border-radius: 0 !important;
  box-shadow: none !important;
}

#loading {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  /*opacity: 75% !important;  /* Not sure why Chrome deployed is overriding this */
  display: flex;
  justify-content: center;
  align-items: center;
}

#downloadSelector::after {
  display: none;
}

.card-header .btn {
  padding: 0;
}

.image-viewer {
  height: 33vw;
  padding: 0.5rem 1.25rem;
  background: linear-gradient(#ffffff, rgba(28, 182, 245, 0.3));
  /*background: linear-gradient(rgba(28, 182, 245, 0.3), #ffffff);*/
  max-height: 33%;
}

#model-footer {
  padding: 0;
}

#model-footer .btn {
  border: none;
}

#model-footer .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-left-radius: 0;
}

.blinking {
  -webkit-animation: glowing 3000ms ease infinite;
  -moz-animation: glowing 3000ms ease infinite;
  -o-animation: glowing 3000ms ease infinite;
  animation: glowing 3000ms ease infinite;
  /*background: transparent;*/
  background: linear-gradient( rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.7));
  /*background: radial-gradient(transparent, transparent); */
}

@-webkit-keyframes glowing {
  0% { background-color: none; -webkit-box-shadow: 0; }
  50% { background-color: rgba(28, 182, 245, 0.2); -webkit-box-shadow: 0 0 5px rgba(28, 182, 245, 0.2); }
  100% { background-color: none; -webkit-box-shadow: 0; }
}

@keyframes glowing {
  0% { background-color: none; box-shadow: 0; }
  50% { background-color: rgba(28, 182, 245, 0.2); box-shadow: 0 0 5px rgba(28, 182, 245, 0.2); }
  100% { background-color: none; box-shadow: 0; }
}

.blinking:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  animation: none;
  background: #6c757d;
}

label {
  margin: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
